import { useAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaTelegram } from 'react-icons/fa';

// import logo from '../assets/images/logo/netconn_engineering.png';
// import logo from '../assets/images/logo/company_logo.png';
import logo from '../assets/images/logo/logo3d.png';

import './footer.css';

function Footer() {
  const { currentLanguage, currentPage } = useAppContext();

  const currentGregorianYear = new Date().getFullYear();
  let currentEthiopianYear = currentGregorianYear - 8;
 

  const currentDate = new Date().toLocaleString('en-US', { timeZone: 'Africa/Addis_Ababa' });
  const [month, day] = currentDate.split('/');

  const formattedCurrentYear = () => {

    if (currentLanguage === 'አማርኛ') {

            // Adjust for years after September 11
        if (parseInt(month) > 9 || (parseInt(month) === 9 && parseInt(day) > 11)) {
        currentEthiopianYear++;
        
        
      }
      return (
        <span className='current-year'>
          {currentEthiopianYear}
        </span>
      )
    }
    else{
      return (
        <span className='current-year'>
          {currentGregorianYear}
        </span>
      )

    }
    
  }

  const formattedCompanyName = (inHeader) => {

    const companyName = currentLanguage === 'አማርኛ' ? 'ብሮስፌ' : 'brosfe';
    // const companyName = currentLanguage === 'አማርኛ' ? 'ኔትኮን ኢንጅነሪንግ' : 'netconn Engineering';
    const nameLength = companyName.length;

    return (
      <span className='company-name'>
        {companyName.split(' ').map((word, wordIndex) => (
          inHeader ? (
            <span key={wordIndex}>
              {word.split('').map((char, charIndex) => (
                <span
                  key={charIndex}
                  style={
                    (wordIndex === 0) // Check if it's 'Puzzle' and apply color
                      ? { color: '#f5f5f5', textShadow: '0 4px 12px rgba(200, 200, 200, 0.5)' }
                      : { color: '#ffd230' } // Default color for 'Designs'
                  }
                >
                  {char}
                </span>
              ))}
              {wordIndex === 0 ? ' ' : ''} {/* Add space between words */}
            </span>
          ) :
            (
              <span key={wordIndex}>
                {word.split('').map((char, charIndex) => (
                  <span
                    key={charIndex}
                    style={
                      (wordIndex === 0 && charIndex !== char.length - 1) // Check if it's 'Puzzle' and apply color
                        ? { color: '#c0c0c0' }
                        : { color: '#f6f6f6' } // Default color for 'Designs'
                    }
                  >
                    {char}
                  </span>
                ))}
                {wordIndex === 0 ? ' ' : ''} {/* Add space between words */}
              </span>
            )
        )
        )
        }
      </span>
    );
  };
  return (
    <div className='footer'>
      {currentLanguage === 'English' && (
        <div className='footer-container'>
          <div className='footer-navs'>
            <div className='column'>
              <h2 className='column-title'>Company</h2>
              <ul className='column-content'>

                <li>About</li>
                <li>Code of Conduct</li>
                <li>Refund & Cancellation policy</li>
              </ul>
            </div>
            <div className='column'>
              <h2 className='column-title'>Products</h2>
              <ul className='column-content'>
              <li className='footer-link'>
                  <Link to="/portfolio"
                  style={{color: '#000'}}>Website Design</Link>
                </li>
                <li className='footer-link'>
                  <Link to="/portfolio"
                  style={{color: '#000'}}>App Design</Link>
                </li>
                <li className='footer-link'>
                  <Link to="/portfolio"
                  style={{color: '#000'}}>Software Solutions</Link>
                </li>

              </ul>
            </div>
            <div className='column'>
              <h2 className='column-title'>Resources</h2>
              <ul className='column-content'>
              <li className='footer-link'>
                  <Link to="/blog"
                  style={{color: '#000'}}>Blog</Link>
                </li>
              </ul>
            </div>
            <div className='column'>
              <h2 className='column-title'>Social</h2>
              <ul className='column-content'>

                <li className='footer-link social'>
                  <a href="https://www.linkedin.com/in/biruke-abraha/" target="_blank"
                  style={{color: '#000'}}><FaLinkedin /></a>
                </li>
                <li className='footer-link social'>
                  <a href="https://t.me/brukByte" target="_blank"
                  style={{color: '#000'}}><FaTelegram /></a>
                </li>
              </ul>
            </div>
            <div className='column'>
              <h2 className='column-title'>Address</h2>
              <ul className='column-content'>
                <li>Tel: +251-974-052565</li>
                <li>Email: info@brosfe.com</li>
                <li>Addis Ababa, Ethiopia</li>

              </ul>
            </div>
          </div>
          <div className='footer-motto'>
            <p><span className='company-name-footer'>{formattedCompanyName(true)}</span> <span style={{ color: '#4ddde0', letterSpacing: '3px' }}>| Tech Innovation Beyond Boundaries</span><br />Elevate Your Local Business in Ethiopia with Expert Software and Tech Solutions<br />
              | Website & App Design | Office Software Solutions | AI Consultancy | Branding |<br />
            </p>
            <img src={logo} className="logo-footer" alt="company logo for brosfe" />
          </div>
        <div className='footer-motto' style={{width:'100%', backgroundColor: '#050505'}} >
    
       
     </div>
          <div className='footer-note'>
            {/* <p>Copyright &copy; 2023. All rights reserved.</p> */}
            <p>Copyright &copy; {formattedCurrentYear()}. All rights reserved.</p>

          </div>
          <div></div>
        </div>


      )}
      {currentLanguage === 'አማርኛ' && (
       <div className='footer-container'>
       <div className='footer-navs'>
         <div className='column'>
           <h2 className='column-title'>ድርጅት</h2>
           <ul className='column-content'>

             <li>ስለ እኛ</li>
             <li>የስነምግባር ደንብ</li>
             <li>የተመላሽ ገንዘብ እና የስረዛ መመሪያ</li>
           </ul>
         </div>
         <div className='column'>
           <h2 className='column-title'>አግልግሎቶች</h2>
           <ul className='column-content'>
           <li className='footer-link'>
               <Link to="/portfolio"
               style={{color: '#000'}}>ዌብሳይት ዲዛይን</Link>
             </li>
             <li className='footer-link'>
               <Link to="/portfolio"
               style={{color: '#000'}}>መተግበሪያ ዲዛይን</Link>
             </li>
             <li className='footer-link'>
               <Link to="/portfolio"
               style={{color: '#000'}}>ሶፍትዌር መፍትሄዎች</Link>
             </li>

           </ul>
         </div>
         <div className='column'>
           <h2 className='column-title'>ይዘቶች</h2>
           <ul className='column-content'>
           <li className='footer-link'>
               <Link to="/blog"
               style={{color: '#000'}}>ስነጽሁፍ</Link>
             </li>
           </ul>
         </div>
         <div className='column'>
           <h2 className='column-title'>ማህበራዊ ገጾች</h2>
           <ul className='column-content'>

             <li className='footer-link social'>
               <a href="https://www.linkedin.com/in/biruke-abraha/" target="_blank"
               style={{color: '#000'}}><FaLinkedin /></a>
             </li>
             <li className='footer-link social'>
               <a href="https://t.me/brukByte" target="_blank"
               style={{color: '#000'}}><FaTelegram /></a>
             </li>
           </ul>
         </div>
         <div className='column'>
           <h2 className='column-title'>አድራሻ</h2>
           <ul className='column-content'>
             <li>ስልክ:  +251-974-052565</li>
             <li>ኢሜይል: info@brosfe.com</li>
             <li>አዲስ አበባ ፤ ኢትዮጵያ</li>

           </ul>
         </div>
       </div>
       <div className='footer-motto'>
         <p><span className='company-name-footer'>{formattedCompanyName(true)}</span> <span style={{ color: '#4ddde0', letterSpacing: '3px' }}>| የቴክኖሎጂ መፍትሄዎችን ያለ ወሠን</span><br />በኢትዩጵያ የሚገኝ አገልግሎትዎንና ንግድዎን በባለሙያ የሶፍትዌር እና የቴክ ሶሉሽን የተሠሩ መፍትሄዎች ይደግፉ<br />
         | ዌብሳይትና መተግበሪያ ዲዛይን | የቢሮ ሶፍትዌር መፍትሄዎች | AI ኮንሳልቲንግ | ብራንዲንግ |<br />
         </p>
          <img src={logo} className="logo-footer" alt="company logo for brosfe" />
       </div>
       <div className='footer-motto' style={{width:'100%', backgroundColor: '#050505'}} >
     
     </div>
       <div className='footer-note'>
         <p>ኮፒ ራይት &copy; {formattedCurrentYear()} ዓ.ም. ፤ መብቱ በህግ የተጠበቀ ነው፡፡</p>

       </div>
       <div></div>
     </div>



      )}

    </div>

  );
}

export default Footer;
