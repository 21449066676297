// NavigationBar.js
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate,useLocation, } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import logo from '../assets/images/logo/company_logo_tiny.png';
// import logo from '../assets/images/logo/company_logo_zoomed.png';
// import logo from '../assets/images/logo/netconn_engineering-compact.png';
import LanguageSelector from './LanguageSelector';
import './NavigationBar.css';
import { color, motion, AnimatePresence } from "framer-motion";

const NavigationBar = () => {
  const { currentLanguage, currentPage } = useAppContext();
  const [isScrolled, setIsScrolled] = useState(0);
  const [isScrolledPortfolio, setIsScrolledPortfolio] = useState(0);
  const largeScale = 4.9;
  const smallScale = 0.8;
  const largeScalePortfolio = 3;
  const smallScalePortfolio = 1.8;

  useEffect(() => {
    const handleScroll = () => {
      const scrolledPixels = window.scrollY;

      // Check for the first state variable (isScrolled)
      const isScrolledValue = scrolledPixels >= largeScale * window.innerHeight
        ? 2
        : scrolledPixels > smallScale * window.innerHeight
          ? 1
          : 0;

      // Check for the second state variable (isScrolledPortfolio)
      const isScrolledPortfolioValue = (scrolledPixels >= smallScalePortfolio * window.innerHeight) &&
        (scrolledPixels < largeScalePortfolio * window.innerHeight)
        ? 2
        : 0;


      // Set the state variable based on your naming convention
      setIsScrolled(isScrolledValue);
      setIsScrolledPortfolio(isScrolledPortfolioValue);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getHeaderStyles = ({ currentPage }) => {
    return currentPage === "Home" ? {
      backgroundColor: isScrolled === 2 ? '#F5F5F5' : 'transparent',
      position: isScrolled === 1 ? 'static' : 'sticky',

    } : currentPage === "Portfolio" ? {
      backgroundColor: isScrolledPortfolio === 2 ? 'transparent' : '#F5F5F5',
      position: isScrolledPortfolio === 2 ? 'static' : 'sticky',

    } : {
      backgroundColor: '#F5F5F5',
      position: 'sticky',
    }
  }

  const headerStyle = {
    top: 0,
    zIndex: 100,
    ...getHeaderStyles({ currentPage }),



  };

  const navigationItemsEnglish = [
    {
      id: 1,
      name: 'Home',
      path: '/',
    },
    {
      id: 2,
      name: 'Portfolio',
      path: '/portfolio',
    },
    {
      id: 3,
      name: 'Pricing',
      path: '/pricing',
    },
    {
      id: 4,
      name: 'About',
      path: '/about',
    },
    {
      id: 5,
      name: 'Contact Us',
      path: '/contactus',
    },
    {
      id: 6,
      name: 'Blog',
      path: '/blog',
    },
  ]
  const navigationItemsAmharic = [
    {
      id: 1,
      name: 'መነሻ',
      path: '/',
    },
    {
      id: 2,
      name: 'ፖርትፎልዮ',
      path: '/portfolio',
    },
    {
      id: 3,
      name: 'ዋጋ',
      path: '/pricing',
    },
    {
      id: 4,
      name: 'ስለኛ',
      path: '/about',
    },
    {
      id: 5,
      name: 'ያግኙን',
      path: '/contactus',
    },
    {
      id: 6,
      name: 'ብሎግ',
      path: '/blog',
    },
  ];
  




  const AnimatedLetter = ({ character, animation, keyUnique }) => {
    return (
      <motion.span
        variants={animation}
        key={keyUnique}
        style={{ position: 'relative', whiteSpace: 'nowrap', display: 'inline-block' }}
      >
        {character}
      </motion.span>
    );
  };

  const AnimatedWord = ({ name, animation, index }) => {
    return (
      <motion.span
        variants={titleAnimation}
        initial="rest"
        animate={hoveredStates[index] ? 'hover' : 'rest'}
        style={{ position: 'relative', whiteSpace: 'nowrap' }}
      >
        {name.split('').map((character, i) =>
          character === ' ' ? (
            <span key={`space-${i}`}>&nbsp;</span>
          ) : (
            <AnimatedLetter character={character} animation={animation} key={`letter-${i}`} />
          )
        )}
      </motion.span>
    );
  };


  const AnimatedLink = ({ item, index, hambergerMenu }) => {
    const { name, path } = item;
    const isActive = window.location.pathname === path;
    const [isActiveHovered, setIsActiveHovered] = useState(false);

    const navigate = useNavigate();
  const location = useLocation();

  // Determine if the 'blog' link or any of its child routes is active
  const isBlogActive = item.name === 'Blog' && location.pathname.startsWith('/blog');


    const activeStyle = {
      backgroundColor: '#000000',
      color: '#c7e6d7',
      borderRadius: '5px',
      padding: '0 5px',

    };
    const activeHoveredStyle = {
      // backgroundColor: '#ffd230',
      backgroundColor: '#474554',
      color: '#c7e6d7',
      borderRadius: '5px',
      padding: '0 5px',

    }

    return (
      <motion.div

        style={{
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer',
          textAlign: 'center',


        }}

        onMouseEnter={() => {
          if (!isActive && !hambergerMenu) {
            handleHover(index);
          } else {
            setIsActiveHovered(true);

          }
        }}
        onMouseLeave={() => {
          handleLeave(index);
          setIsActiveHovered(false);
        }}
        key={`link-${item.name}-${index}`}



      >
        <NavLink to={path} style={isActive ? isActiveHovered ? activeHoveredStyle : activeStyle : {}}
        className={isBlogActive ? 'active-link' : ''}

        >
          <AnimatedWord name={name} animation={letterAnimation} index={index} />
          <div style={{ position: 'absolute', top: 0 }}
            onMouseLeave={() => handleLeave(index)}>
            <AnimatedWord name={name} animation={letterAnimationTwo} index={index} />
          </div>
        </NavLink>
      </motion.div>
    );
  };


  const selectedNavigationItems = currentLanguage === 'English' ? navigationItemsEnglish : navigationItemsAmharic;

  const [hoveredStates, setHoveredStates] = useState(
    selectedNavigationItems.map(() => false)
  );



  const handleHover = (id) => {
    setHoveredStates((prev) => {
      const updatedStates = prev.map((state, index) => (index === id ? true : false));

      return updatedStates;
    });
  };


  const handleLeave = (id) => {
    setHoveredStates((prev) => {
      const updatedStates = prev.map((state, index) => (index === id ? false : state));

      return updatedStates;
    });
  };





  const titleAnimation = {
    rest: {
      transition: {
        staggerChildren: 0.003,
      },
    },
    hover: {
      transition: {
        staggerChildren: 0.003,
      },
    },
    exit: {  // Define exit state
      opacity: 0,
      transition: {
        staggerChildren: 0.003,
      },
    },
  };


  const letterAnimation = {
    rest: {
      y: 0,
    },
    hover: {
      y: -30,
      transition: {
        duration: 0.3,
        ease: [0.6, 0.01, 0.05, 0.95],
        type: "tween",
      },
    },
    exit: {  // Define exit state
      opacity: 0,
      y: 50,
      transition: {
        duration: 0.3,
        ease: [0.6, 0.01, 0.05, 0.95],
        type: "tween",
      },
    },
  };


  const letterAnimationTwo = {
    rest: {
      y: 30,

    },
    hover: {
      y: 0,



      transition: {
        duration: 0.3,
        ease: [0.6, 0.01, 0.05, 0.95],
        type: "tween",
      },
    },
    exit: {  // Define exit state
      opacity: 0,
      y: 50,
      transition: {
        duration: 0.3,
        ease: [0.6, 0.01, 0.05, 0.95],
        type: "tween",
      },
    },
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600 && menuOpen) {
        // Reset the state if the screen size is greater than 600px and menuOpen is true
        setMenuOpen(false);
      }
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);

  useEffect(() => {

    const handleOutsideClick = (e) => {
      if (menuOpen && !e.target.closest('.hamburger-menu') &&
        !e.target.closest('.nav-links')) {
        const navLinks = document.querySelector('.nav-links');
        navLinks.classList.remove('show');
        setTimeout(() => {

          setMenuOpen(!menuOpen);
        }, 1000);
      }
    };
    const handleNavLinkClick = (e) => {

      if (menuOpen && e.target.closest('.nav-links')) {
        const navLinks = document.querySelector('.nav-links');
        navLinks.classList.remove('show');
        setTimeout(() => {

          setMenuOpen(!menuOpen);
        }, 700);
      }
    };


    document.body.addEventListener('click', handleOutsideClick);
    document.querySelector('.nav-links').addEventListener('click', handleNavLinkClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [menuOpen, setMenuOpen]);

  return (
    <div style={headerStyle} className='header'>
      <AnimatePresence mode='concurrent'>
        <div className="Web-logo"
        key="logo">
          <NavLink to="/" exact="true">
            <img src={logo} className="logo" alt="company logo" />
          </NavLink>
        </div>


        <div className='header-nav'
        key={`headernav-${currentLanguage}`}>
          <div className='navigation-container' style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}>


            <div className={`nav-links ${menuOpen ? 'show' : ''}`}>
              {selectedNavigationItems.map((item, index) => (
               <div key={`${item.id}-${item.name}`} style={{ textAlign: 'center' }}>
                  <AnimatedLink item={item} index={index} hambergerMenu={menuOpen} />
                </div>

              ))}
              <div id='language-selector'>
                <LanguageSelector />
              </div>
            </div>



            <div className='hamburger-menu' onClick={toggleMenu} >
              {menuOpen ? '✕' : '☰'}
            </div>
          </div>
        </div>



      </AnimatePresence>
    </div>

  );
}

export default NavigationBar;
