import { useAppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { motion } from 'framer-motion';

import Footer from './Footer';

import AboutInvite from './AboutInvite';
import Affiliations from './Affiliations';
import Transition from './Transition';
import ReachYouForm from './ReachYouForm';
import { FaPhone, FaEnvelope, FaLinkedin, FaArrowRight, FaTimes } from 'react-icons/fa';
import ceoPic from '../assets/images/team/ceo.png'
import skillsLogo from '../assets/images/about/toolsLogos.png'

import logo from '../assets/images/logo/company_logo.png';
import logoMini from '../assets/images/logo/company_logo_mini.png';
import businessCard from '../assets/images/about/business_card.png';
import promoFacts from '../assets/images/about/factsCompany.png'
import promoFactsAmh from '../assets/images/about/factsCompanyAmh.png'
import downloadIcon from '../assets/images/icons/download.png';

import './about.css';
import ScreenSize from './ScreenSize';


function About() {
  const { currentLanguage, setPage } = useAppContext();
  const navigate = useNavigate();

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getTransformStyle = () => {
    // Adjust the factor based on your preference
    const factor = 0.1;
    const translateX = `${scrollPosition * factor}px`;
    return { transform: `translateX(${translateX})` };
  };

  const formattedCompanyName = (inHeader) => {
    const companyName = currentLanguage === 'አማርኛ' ? 'ብሮስፌ' : 'brosfe';
    // const companyName = currentLanguage === 'አማርኛ' ? 'ኔትኮን ብሮስፌ' : "netconn brosfe";
    const nameLength = companyName.length;

    return (
      <span className='company-name'>
        {companyName.split(' ').map((word, wordIndex) => (
          inHeader ? (
            <span key={wordIndex}>
              {word.split('').map((char, charIndex) => (
                <span
                  key={charIndex}
                  style={
                    (wordIndex === 0) // Check if it's 'Puzzle' and apply color
                      ? { color: '#000000', borderBottom: '1px solid #474554' }
                      : { color: '#474554', textShadow: ' 4px 4px 12px rgba(255, 255, 0, 0.5)' } // Default color for 'Designs'
                  }
                >
                  {char}
                </span>
              ))}
              {wordIndex === 0 ? '' : ''} {/* Add space between words */}
            </span>
          ) :
            (
              <span key={wordIndex}>
                {word.split('').map((char, charIndex) => (
                  <span
                    key={charIndex}
                    style={
                      (wordIndex === 0 && charIndex % 2 === 1)
                        ? { color: '#474554' }
                        : { color: '#474554' }
                    }
                  >
                    {char}
                  </span>
                ))}
                {wordIndex === 0 ? '' : ''}
              </span>
            )
        )
        )
        }
      </span>
    );
  };

  const handleContactUs = () => {

    navigate('/contactus');
  };


  const [getInTouch, setGetInTouch] = useState(false);
  const [emailUs, setEmailUs] = useState(false);
  const [callUs, setCallUs] = useState(false);
  const CtaButton = () => {
    const buttonLabel = currentLanguage === 'English' ? 'Get in touch' : 'ያግኙን';
    return (
      <button className="cta-button" onClick={handleCTAClick}
        style={{
          transform: `${getInTouch ? "translateY(-10px) scale(0.8)" : ""} ${emailUs ? "translateY(-20px)" : ""}`,
          filter: getInTouch ? "grayscale(100%)" : "none",
          fontWeight: getInTouch ? "normal" : "bold",
        }}>{buttonLabel}</button>
    )
  }

  const handleCTAClick = () => {
    setGetInTouch(true);
  }

  const handleEmailUs = () => {
    setEmailUs(true);
    setCallUs(false);
  }
  const handleCallUs = () => {
    setCallUs(true);
    setEmailUs(false);
  }

  const handleCancel = () => {
    setGetInTouch(false);
    setEmailUs(false);
  }


  useEffect(() => {
    setPage('About');
  }, [])

  const bgColorPic = ['#ffd230', '#C7E6D7'];
  const [backgroundColorPic, setBackgroundColorPic] = useState('#ffd230')
  const handleContactMouseEnter = () => {
    let index = 0;
    const intervalId = setInterval(() => {
      index++;
      setBackgroundColorPic(bgColorPic[index % 2]);

      if (index >= 6) {
        clearInterval(intervalId);

      }


    }, 300);

    return () => {
      // Clean up
      clearInterval(intervalId);
    };
  };

  const downloadBusinessCard = () => {
    const cardContainer = document.querySelector('.business-card-container');

    html2canvas(cardContainer)
      .then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL();
        link.download = 'brosfe-business-card.png';
        link.click();
      });
  };



  const pageSectionColors = ['#eceee0', '#ddd'];
  // const pageSellingsColors = ['#ffaaaa','#aaffaa'];
  const pageSellingsColors = ['#f5f5f5', '#474554'];

  const call_us = currentLanguage === 'English' ? 'Call Us' : 'ይደውሉልን';
  const email_us = currentLanguage === 'English' ? 'Email Us' : 'ኢሜይል ይጻፉልን';
  const download_label = currentLanguage === 'English' ? 'Download Business Card' : 'ቢዝነስ ካርዱን ያውርዱ'
  
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 430);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 430);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='main-container'>
      {currentLanguage === 'English' && (
        <div className='about-container'>

          {/* <ScreenSize /> */}
          <div >
            <div className="motto-about" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <h1><span className='company-name'>{formattedCompanyName(true)}</span> - Crafting Digital Excellence with Passion and Precision through its digital ally <span style={{textTransform:'lowercase'}}>brosfe</span>.</h1>
              
             <div className='promo-img-container'>
              <motion.img
                src={promoFacts}
                alt='company promo, brosfe'
                className='logo-motto2'
                initial={{ scale: 1, y: 0, x: 0 }} // Initial scale and y position
                animate={{ 
                  scale: isLargeScreen ? [1, 4, 1] : [1, 3, 1],
                  y: isLargeScreen?[0, -100, 0]:[0, -20, 0], 
                  x: isLargeScreen?[0, -20, 0]:[0, -20, 0] }} // Animate scale from normal to zoomed and back, and y position
                transition={{ duration: 5, repeat: Infinity }} // Duration of animation and repeat indefinitely
              />
              </div>
            </div>
            <div className='about-company'>

              <p>Welcome to <span className='company-name' style={{

                textTransform: 'lowercase'
              }}>{formattedCompanyName()}</span>, the creative team here to help you navigate your business's digital
                needs and transformations. <span className='company-name' style={{}}>{formattedCompanyName()}</span>, founded in 2023 by Electronics and Software Engineer Biruk Tadesse, is an Amharic technological term and translates to "stylus" in English, a digital pen for use on smart devices. We aim to be the go to solution for all your digital needs and 
                technological transformtions. Our team is driven by curiosity and passion and a
                culture to delivering tailored transformative technology solutions to businesses and enterprises in Ethiopia. <br /><br />
                At <span className='company-name' style={{}}>{formattedCompanyName()}</span>, we aim at empowering Ethiopian or locally targeted enterprises with cutting-edge software and tech services.
                We want to help you explore tailored solutions encompassing website and app design, AI consultancy, office software innovation, and impactful brand designs. Our core values Curiosity, Imagination, Critical Thinking, Creativity and Perseverence (CICCP) help us stay on track with what
                we say we do. We deliver quality and well thought out solutions consistently - <span style={{ textTransform: 'capitalize' }}>all day, all night, all year!</span>
              </p>

              <p>
                <span className='company-name' style={{}}>{formattedCompanyName()}</span> uses a freelance business approach and a flexible and viable work from home methodology. This freelance model helps us
                cut running costs and that inturn makes us more affordable and cheaper than the wider market. Simply put, when we say we work freelance, we mean that we're Cheap, Available, Skilled and Flexible. We kickoff your projects at your
                convenient time and location. We're availabe when you are. We track our projects with well organized workflow systems which help us deliver validated and verified projects in time and in scope.
              </p>
              <p>We're firm believers in what the salesman Mr. Dwight Schrute from the US sitcom 'The Office' said to one of his future clients - "I never take vacations. I never get sick and I don't celebrate any
                major holidays." So, take our card below as a snapshot or a printout. It has got our cell number, our email, and a link to <span className='company-name' style={{}}>{formattedCompanyName()}</span>'s homepage.</p>
              <p className='about-reNiche'>Website Design | App Design | Brand Design | Office Software Solutions | AI Powered Advancements | Tailored to Ethiopia's Market </p>
            </div>

            <div className='business-card-section'>
              <div
                className='business-card-container'>

                <img src={businessCard} alt='company business card, brosfe'></img>

              </div>
              <button onClick={downloadBusinessCard}
                className='cta-button-small'>
                <img src={downloadIcon} alt="Downaload icon" className='visit-icon' /> {download_label}
              </button>

            </div>
          </div>

          <div className='about-sellingpoints' style={{ backgroundColor: pageSectionColors[1] }}>
            <h2 className='sub-header'><span className='company-name' style={{ lineHeight: '1.5', letterSpacing: '1.2rem', fontSize: '48px' }}>{formattedCompanyName(false)}</span><br /> at a glance</h2>

            <div style={{ color: pageSellingsColors[1], backgroundColor: pageSellingsColors[0], margin: 'auto' }}
              className='sellingpoints-container'>


              <div
                style={{
                  position: 'relative',
                  backgroundColor: pageSellingsColors[1],
                }}
                className='sellingpoints-title'>
                <p style={{ zIndex: '98', color: pageSellingsColors[0] }}>Quick Facts</p>
                {/* <div style={{ backgroundColor: '#ffd230', width: '50px', height: '50px' }} className='bubble'></div> */}
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    left: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 210, 30, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    right: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 150, 120, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLightRight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
              </div>
              <ul className='intro-text'>
                <li>Innovative software and tech service in Ethiopia</li>
                <li>Founded in 2023</li>
                <li>7+ years of experience</li>
                <li>Guided by strong will to help businesses thrive</li>

              </ul>

            </div>
            <div style={{ color: pageSellingsColors[0], backgroundColor: pageSellingsColors[1], margin: 'auto' }}
              className='sellingpoints-container'>


              <div className='sellingpoints-title'
                style={{
                  backgroundColor: pageSellingsColors[0],
                  position: 'relative'
                }}>
                <p style={{ zIndex: '98' }}>Services and Products</p>
                <div style={{
                  backgroundColor: '#896862', position: 'absolute',
                  top: '50%', left: '50%'
                }} className='medium-bubble-intro'></div>
              </div>
              <ul className='intro-text'
                style={{ color: pageSellingsColors[0] }}>
                <li>Website Designs</li>
                <li>App Design</li>
                <li>Brand Design</li>
                <li>Office Software Solutions</li>
                <li>AI Powered Advancements</li>

              </ul>

            </div>

            <div style={{ color: pageSellingsColors[1], backgroundColor: pageSellingsColors[0], margin: 'auto' }}
              className='sellingpoints-container'>
              <div style={{
                position: 'relative',
                backgroundColor: pageSellingsColors[1]
              }} className='sellingpoints-title'>
                <p style={{ zIndex: '98', color: pageSellingsColors[0] }}>Business Niche</p>
                {/* <div style={{ backgroundColor: '#ffff00', width: '150px', height: '150px' }} className='bubble'></div> */}
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    left: 0,
                    width: '25px', // Adjust the width to control the size of the point of light
                    height: '25px', // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 210, 30, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    right: 0,
                    width: '25px', // Adjust the width to control the size of the point of light
                    height: '25px', // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 150, 50, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLightRight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
              </div>

              {/* <h3 className='about-title'>Focus Areas</h3> */}
              <ul className='intro-text'>
                <li>Office</li>
                <li>Ecommerce</li>
                <li>Social services </li>
                <li>Education</li>
                <li>Tourism</li>
                <li>Automation</li>
              </ul>
            </div>

            <div style={{ color: pageSellingsColors[0], backgroundColor: pageSellingsColors[1], margin: 'auto' }}
              className='sellingpoints-container'>
              <div className='sellingpoints-title'
                style={{
                  overflow: 'hidden',
                  backgroundColor: pageSellingsColors[0],
                  position: 'relative'
                }}>
                <p style={{ zIndex: '98' }}>Business Approaches</p>

                <div style={{
                  backgroundColor: '#ffff00', position:
                    'absolute', top: '50%', left: '50%'
                }} className='medium-bubble-intro'></div>

              </div>

              <ul className='intro-text'
                style={{ color: pageSellingsColors[0] }}>

                <li>Professional</li>
                <li>Structured</li>
                <li>Timely followup</li>
                <li>Tailored design</li>
                <li>On-time delivery</li>
                <li>Cooperative approach</li>
              </ul>
            </div>

            <div className='sellingpoints-container'
              style={{ color: pageSellingsColors[1], backgroundColor: pageSellingsColors[0], margin: 'auto' }}>
              <div className='sellingpoints-title'
                style={{
                  overflow: 'hidden',
                  backgroundColor: pageSellingsColors[1],
                  position: 'relative'
                }}>
                <p style={{ zIndex: '98', color: pageSellingsColors[0] }}>Tools and Technologies</p>

                {/* <div style={{ backgroundColor: '#C7E6D7', overflowY: 'hidden' }} className='medium-bubble-intro'></div> */}

                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    left: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 210, 30, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    right: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 150, 120, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLightRight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
              </div>
              <div className='intro-text-img'>
                <div >
                  <img src={skillsLogo} alt="Representation of brosfe's core competencies, including expertise in software development, hardware solutions, and efficient project management."


                  ></img>

                </div>
              </div>


            </div>
            {/* <HorizontalScrollLogo /> */}






          </div>
          <div style={{ padding: '24px', backgroundColor: pageSectionColors[0] }}>

            <AboutInvite />

          </div>

          <div
            style={{
              backgroundColor: pageSectionColors[1],


            }}
            className='team-container'>

            <div className='member-container'>
              <img src={ceoPic}
                alt="Portrait of Bruk Tadesse, Founder and Software/Electronics Engineer at brosfe."

                className='teamPic'
                style={{ backgroundColor: backgroundColorPic }}></img>
              <div className='member-info'>
                <p className='name'>Biruk Tadesse</p>
                <p className='title'>Founder and Software/Electronics Engineer @brosfe</p>
                <p className='areas'>Website | App | Brand | Office Software | AI Consultancy</p>
                <button className="cta-contactus" onClick={handleContactUs}
                  onMouseEnter={handleContactMouseEnter}
                >Contact Us</button>
              </div>
            </div>





          </div>
          <div style={{ backgroundColor: pageSectionColors[0] }}>
            <Affiliations />
          </div>

          <div className='cfa'
            style={{ position: 'relative' }}>
            <CtaButton />
            {getInTouch && (

              <button onClick={() => handleCancel()}
                className='cancel-button'>
                <FaTimes />
              </button>


            )}
            {getInTouch && (

              <div style={{
                display: 'flex', justifyContent: 'space-evenly', width: '100%',
                boxShadow: '4px 4px 12px rgba(255, 216, 178, 0.5)',
                transform: emailUs ? "translateY(-10px)" : '',

              }}>
                <div style={{
                  backgroundColor: '#aaffaa', padding: '10px',
                  filter: emailUs ? "grayscale(100%)" : "none",
                  opacity: emailUs ? '0.5' : '1',
                  border: callUs ? '2px solid #474554' : '',
                  borderRadius: callUs ? '10px 4px' : '',
                  fontWeight: callUs ? 'bold' : 'normal',
                }}
                  className='contact-item'
                  onClick={handleCallUs}>
                  <p>
                    <FaPhone /> <a href="tel:+251974052565" >Call Us</a>
                  </p>
                </div>

                <div style={{
                  backgroundColor: '#aaaaff', padding: '10px',
                  cursor: 'pointer', border: emailUs ? '2px solid #474554' : '',
                  borderRadius: emailUs ? '10px 4px' : '',
                  fontWeight: emailUs ? 'bold' : 'normal',
                  filter: callUs ? "grayscale(100%)" : "none",
                  opacity: callUs ? '0.5' : '1'
                }}
                  className='contact-item'
                  onClick={handleEmailUs}
                >
                  <p>
                    <FaEnvelope /> Email Us
                  </p>

                </div>
              </div>

            )}
            {getInTouch && (

              <div
                className='cfa-logo-container'>
                <img src={logoMini} alt='company logo, brosfe' className='logo-cfa'></img>
              </div>


            )}
            {emailUs && getInTouch && (
              <div className='reach-you-form-container'>
                <ReachYouForm />
              </div>



            )}


          </div>
          <Footer />
        </div>


      )}
      {currentLanguage === 'አማርኛ' && (
        <div className='about-container'>

          {/* <ScreenSize /> */}
          <div >
            <div className="motto-about" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <h1> <span className='company-name'>{formattedCompanyName(true)}</span>
                እንባላለን ፤ ዲጂታል ልቀትን ከልብና ለለውጥ የምንሠራ</h1>
                <div className='promo-img-container'>
              <motion.img
                src={promoFactsAmh}
                alt='company promo, brosfe'
                className='logo-motto2'
                initial={{ scale: 1, y: 0, x: 0 }} // Initial scale and y position
                animate={{ 
                  scale: isLargeScreen ? [1, 4, 1] : [1, 3, 1],
                  y: isLargeScreen?[0, -100, 0]:[0, -20, 0], 
                  x: isLargeScreen?[0, -20, 0]:[0, -20, 0] }} // Animate scale from normal to zoomed and back, and y position
                transition={{ duration: 5, repeat: Infinity }} // Duration of animation and repeat indefinitely
              />
              </div>

            </div>
            <div className='about-company'>

              <p>
                ወደ <span className='company-name' style={{

                  textTransform: 'lowercase'
                }}>{formattedCompanyName()}</span> እንኳን በደህና መጡ! ድርጅታችን የንግድዎን የዲጂታል ፍላጎቶች እና ለውጦችን እንዲያስሱ እንዲሁም እንዲገነቡ የሚረዳዎት የፈጠራ ቡድን ነው። እ.ኢ.አ. በ2016 በኤሌክትሮኒክስ እና ሶፍትዌር መሃንዲስ ብሩክ ታደሰ የተመሰረተው ድርጅታችን ብሮስፌ(ብዕር + ወስፌ) የአማርኛ የቴክኖሎጂ ቃል ሲሆን ትርጉሙም ዲጂታል እስክርቢቶ ማለት ነው፡፡ እኛም በኢትዮጵያ ውስጥ ላሉ ንግዶች እና ኢንተርፕራይዞች ተጣጥመው የሚሠሩ የለውጥ ቴክኖሎጂ መፍትሄዎችን ለማቅረብ ሠፊ ትልም ይዘን ተቋቁመናል።

                የ<span className='company-name' style={{}}>{formattedCompanyName()}</span> ዓላማ ኢትዮጵያዊያንን ወይም በአገር ውስጥ የሚሠሩ ኢንተርፕራይዞችን በዘመናዊ ሶፍትዌር እና የቴክኖሎጂ አገልግሎቶች ብቁ ፈጣንና ተወዳዳሪ እንዲሆኑ የበኩሉን ማበርከት ነው። የዌብሳይትና የስልክ መተግበሪያ ዲዛይኖች፤ የቢሮ ሶፍትዌር ሥራዎች፤ AI ኮንሳልቲንግ እና ተፅዕኖ ያላቸው ዲዛይኖችን በብቃት እንገነባለን። የእኛ ዋና እሴቶቻችን የማወቅ ጉጉት፣ ምናባዊ አስተሳሰብ፣ ሂሳዊ አስተሳሰብ፣ ፈጠራ እና ጽናት (CICCP) ልንሠራቸው አቅደን ለተነሳናቸው ዓላማዎች ጸንተን እንድንቀጥል ይረዱናል። ጥራት ያላቸውንና አርኪ መፍትሄዎችን በተከታታይ እናቀርባለን - ሙሉ ቀን ፣ ሙሉ ሌሊት ፣ ሙሉ ዓመት!
              </p>

              <p>
                <span className='company-name' style={{}}>{formattedCompanyName()}</span> የፍሪላንስ የንግድና የሥራ ሞዴልን ለውጤታማ ስራ ይጠቀምበታል። ይህ የፍሪላንስ ሞዴል ለዚዜው አዋጭ ያልሆኑ ወጪዎችን እንድንቀንስ ከመርዳትም ባለፈ ለሰፊው ገበያ ተመጣጣኝ ዋጋ እንድናቀርብ ይረዳናል። በቀላል አነጋገር፣ ፍሪላንስ እንሰራለን ስንል ርካሽ፣ ቀልጣፋ፣ ባለሙያ እና ለአሰራር ምቹ ነን ማለታችን ነው። ፕሮጀክቶችዎን ባመቸዎ ጊዜ እና ቦታ መጥተን እንጀምራለን። እርስዎ ይንገሩን - እኛ እንገኛለን። ፕሮጀክቶቻችንን በደንብ በተደራጁ የስራ ፍሰት ስርዓቶች እንከታተላለን ፤ ይህም የተረጋገጡና ውጤታማ ፕሮጀክቶችን በጊዜ እና በስፋት ለማቅረብ ይረዳናል።
              </p>
              <p>
                በዩኤስ ሲትኮሙ 'The Office' ላይ እንደ ሽያጭ ባለሙያ ሆኖ የሚሠራው አቶ ድዋይት ሽሩት ለአንድ የወደፊት ደንበኛቸው ድርጅታቸውን ሲያስተዋውቁ "በፍፁም ዕረፍት አልወስድም። በጭራሽ አልታመምም እንዲሁም ምንም አይነት ዋና በዓላትን አላከብርም፡፡" ብሎ የተናገረውን አባባል እኛም እናምንበታለን። ስለዚህ ክስር የተቀመጠውን ካርዳችንን በፎቶ ወይም በህትመት መልክ አውርደው ይያዙት። የሞባይል ቁጥራችንን፣ ኢሜላችንን እና ወደ <span className='company-name' style={{}}>{formattedCompanyName()}</span> መነሻ ገጽ የሚወስድ መረጃን ይዟል፡፡
              </p>
              <p className='about-reNiche'>የዌብሳይት ዲዛይን | የሞባይል መተግበሪያ ዲዛይን | ብራንድ ዲዛይን | የቢሮ ሶፍትዌር መፍትሄዎች | AI ኮንሳልታንሲ | ለኢትዮጵያ ገበያ ታቅዶ የተዘጋጀ </p>
            </div>

            <div className='business-card-section'>
              <div
                className='business-card-container'>

                <img src={businessCard} alt='company business card, brosfe'></img>

              </div>
              <button onClick={downloadBusinessCard}
                className='cta-button-small'>
                <img src={downloadIcon} alt="Downaload icon" className='visit-icon' /> {download_label}
              </button>

            </div>
          </div>

          <div className='about-sellingpoints' style={{ backgroundColor: pageSectionColors[1] }}>
            <h2 className='sub-header'><span className='company-name' style={{ lineHeight: '1.5', letterSpacing: '1.2rem', fontSize: '48px' }}>{formattedCompanyName(false)}</span><br /> በትንሹ</h2>

            <div style={{ color: pageSellingsColors[1], backgroundColor: pageSellingsColors[0], margin: 'auto' }}
              className='sellingpoints-container'>


              <div
                style={{
                  position: 'relative',
                  backgroundColor: pageSellingsColors[1],
                }}
                className='sellingpoints-title'>
                <p style={{ zIndex: '98', color: pageSellingsColors[0] }}>ፈጣን እውነታዎች</p>
                {/* <div style={{ backgroundColor: '#ffd230', width: '50px', height: '50px' }} className='bubble'></div> */}
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    left: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 210, 30, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    right: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 150, 120, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLightRight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
              </div>
              <ul className='intro-text'>
                <li>የፈጠራ ሶፍትዌር እና የቴክኖሎጂ አገልግሎቶች ለኢትዮጵያ ገበያ</li>
                <li>በ2016 ዓ.ም ተመሠረተ</li>
                <li>+7 ዓመት የሥራ ልምድ</li>
                <li>የኢትዮጵያ ቢዝነሶች/ኢንተርፕራይዞች በቴክኖሎጂ እንዲጎለብቱ መርዳት</li>

              </ul>

            </div>
            <div style={{ color: pageSellingsColors[0], backgroundColor: pageSellingsColors[1], margin: 'auto' }}
              className='sellingpoints-container'>


              <div className='sellingpoints-title'
                style={{
                  backgroundColor: pageSellingsColors[0],
                  position: 'relative'
                }}>
                <p style={{ zIndex: '98' }}>አገልግሎቶችና ምርቶች</p>
                <div style={{
                  backgroundColor: '#896862', position: 'absolute',
                  top: '50%', left: '50%'
                }} className='medium-bubble-intro'></div>
              </div>
              <ul className='intro-text'
                style={{ color: pageSellingsColors[0] }}>
                <li>ዌብሳይት ዲዛይን</li>
                <li>ሞባይል መተግበሪያ ዲዛይን</li>
                <li>ብራንድ ዲዛይን</li>
                <li>የቢሮ ሶፍትዌር መፍትሄዎች</li>
                <li>AI መርህ መፍትሄዎች</li>

              </ul>

            </div>

            <div style={{ color: pageSellingsColors[1], backgroundColor: pageSellingsColors[0], margin: 'auto' }}
              className='sellingpoints-container'>
              <div style={{
                position: 'relative',
                backgroundColor: pageSellingsColors[1]
              }} className='sellingpoints-title'>
                <p style={{ zIndex: '98', color: pageSellingsColors[0] }}>ዒላማ ንግድ/ሥራ ዓይነቶች</p>
                {/* <div style={{ backgroundColor: '#ffff00', width: '150px', height: '150px' }} className='bubble'></div> */}
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    left: 0,
                    width: '25px', // Adjust the width to control the size of the point of light
                    height: '25px', // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 210, 30, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    right: 0,
                    width: '25px', // Adjust the width to control the size of the point of light
                    height: '25px', // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 150, 50, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLightRight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
              </div>

              {/* <h3 className='about-title'>Focus Areas</h3> */}
              <ul className='intro-text'>
                <li>ቢሮ</li>
                <li>ኢኮሜርስ</li>
                <li>ማህበራዊ አገልግሎት </li>
                <li>ትምህርት</li>
                <li>ቱሪዝም</li>
                <li>አውቶሜሽን</li>
              </ul>
            </div>

            <div style={{ color: pageSellingsColors[0], backgroundColor: pageSellingsColors[1], margin: 'auto' }}
              className='sellingpoints-container'>
              <div className='sellingpoints-title'
                style={{
                  overflow: 'hidden',
                  backgroundColor: pageSellingsColors[0],
                  position: 'relative'
                }}>
                <p style={{ zIndex: '98' }}>የሥራ አቀራረባችን</p>

                <div style={{
                  backgroundColor: '#ffff00', position:
                    'absolute', top: '50%', left: '50%'
                }} className='medium-bubble-intro'></div>

              </div>

              <ul className='intro-text'
                style={{ color: pageSellingsColors[0] }}>

                <li>ፕሮፌሽናል</li>
                <li>የተዋቀረ</li>
                <li>ወቅታዊ ክትትል</li>
                <li>የተበጀ ንድፍ</li>
                <li>በጊዜ የሚደርስ</li>
                <li>የህብረት ስራ</li>
              </ul>
            </div>

            <div className='sellingpoints-container'
              style={{ color: pageSellingsColors[1], backgroundColor: pageSellingsColors[0], margin: 'auto' }}>
              <div className='sellingpoints-title'
                style={{
                  overflow: 'hidden',
                  backgroundColor: pageSellingsColors[1],
                  position: 'relative'
                }}>
                <p style={{ zIndex: '98', color: pageSellingsColors[0] }}>ቴክኖሎጂዎችና መሳሪያዎች</p>

                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    left: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 210, 30, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '80%',
                    right: 0,
                    width: 25, // Adjust the width to control the size of the point of light
                    height: 25, // Adjust the height to control the size of the point of light
                    borderRadius: '50%', // Create a circular shape
                    background: 'radial-gradient(circle, rgba(255, 150, 120, 0.5) 0%, rgba(255, 255, 255, 0) 80%)',
                    animation: 'moveReflectiveLightRight 10s linear infinite', // Adjust the duration as needed
                  }}
                />
              </div>
              <div className='intro-text-img'>
                <div >
                  <img src={skillsLogo} alt="Representation of brosfe's core competencies, including expertise in software development, hardware solutions, and efficient project management."


                  ></img>

                </div>
              </div>


            </div>
            {/* <HorizontalScrollLogo /> */}






          </div>
          <div style={{ padding: '24px', backgroundColor: pageSectionColors[0] }}>

            <AboutInvite />

          </div>

          <div
            style={{
              backgroundColor: pageSectionColors[1],


            }}
            className='team-container'>

            <div className='member-container'>
              <img src={ceoPic}
                alt="Portrait of Bruk Tadesse, Founder and Software/Electronics Engineer at brosfe."

                className='teamPic'
                style={{ backgroundColor: backgroundColorPic }}></img>
              {currentLanguage === 'English' && (
                <div className='member-info'>
                  <p className='name'>Biruk Tadesse</p>
                  <p className='title'>Founder and Software/Electronics Engineer @brosfe</p>
                  <p className='areas'>Website | App | Brand | Office Software | AI Consultancy</p>
                  <button className="cta-contactus" onClick={handleContactUs}
                    onMouseEnter={handleContactMouseEnter}
                  >Contact Us</button>
                </div>
              )}
              {currentLanguage === 'አማርኛ' && (
                <div className='member-info'>
                  <p className='name'>ብሩክ ታደሰ</p>
                  <p className='title'>መሥራች ፤ ሶፍትዌር/ኤሌክትሮኒክስ መሃንዲስ ፤ ብሮስፌ</p>
                  <p className='areas'>ዌብሳይት | ሞባይል መተግበሪያ | ብራንድ | የቢሮ ሶፍትዌር | AI ኮንሳልታንሲ</p>
                  <button className="cta-contactus" onClick={handleContactUs}
                    onMouseEnter={handleContactMouseEnter}
                  >ያግኙን</button>
                </div>
              )}
            </div>





          </div>
          <div style={{ backgroundColor: pageSectionColors[0] }}>
            <Affiliations />
          </div>

          <div className='cfa'
            style={{ position: 'relative' }}>
            <CtaButton />
            {getInTouch && (

              <button onClick={() => handleCancel()}
                className='cancel-button'>
                <FaTimes />
              </button>


            )}
            {getInTouch && (

              <div style={{
                display: 'flex', justifyContent: 'space-evenly', width: '100%',
                boxShadow: '4px 4px 12px rgba(255, 216, 178, 0.5)',
                transform: emailUs ? "translateY(-10px)" : '',

              }}>
                <div style={{
                  backgroundColor: '#aaffaa', padding: '10px',
                  filter: emailUs ? "grayscale(100%)" : "none",
                  opacity: emailUs ? '0.5' : '1',
                  border: callUs ? '2px solid #474554' : '',
                  borderRadius: callUs ? '10px 4px' : '',
                  fontWeight: callUs ? 'bold' : 'normal',
                }}
                  className='contact-item'
                  onClick={handleCallUs}>
                  <p>
                    <FaPhone /> <a href="tel:+251974052565" >{call_us}</a>
                  </p>
                </div>

                <div style={{
                  backgroundColor: '#aaaaff', padding: '10px',
                  cursor: 'pointer', border: emailUs ? '2px solid #474554' : '',
                  borderRadius: emailUs ? '10px 4px' : '',
                  fontWeight: emailUs ? 'bold' : 'normal',
                  filter: callUs ? "grayscale(100%)" : "none",
                  opacity: callUs ? '0.5' : '1'
                }}
                  className='contact-item'
                  onClick={handleEmailUs}
                >
                  <p>
                    <FaEnvelope /> {email_us}
                  </p>

                </div>
              </div>

            )}
            {getInTouch && (

              <div
                className='cfa-logo-container'>
                <img src={logoMini} alt='company logo, brosfe' className='logo-cfa'></img>
              </div>


            )}
            {emailUs && getInTouch && (
              <div className='reach-you-form-container'>
                <ReachYouForm />
              </div>



            )}


          </div>
          <Footer />
        </div>


      )}

    </div>

  );
}

export default Transition(About, 'About');
